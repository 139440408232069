import React from  'react'
import Layout from '../components/layout'
import patio from '../images/cobblestonestamp.jpg'
import patio1 from '../images/custompatio.jpg'

import epoxy from '../images/epoxy.jpg'
import fireplace from '../images/fireplace.jpg'
import overlay from '../images/overlay.jpg'
import patio4 from '../images/patio.jpeg'
import stamp from '../images/redstamp.jpg'
import patio5 from '../images/smallpatio.jpeg'
import stamped from '../images/stamped patio.jpg'
import stampedPatio from '../images/stamped.jpeg'
import stone from '../images/stone.jpg'
import wood from '../images/wood_stamped.jpeg'
import SEO from '../components/seo'

class Gallery extends React.Component {
    render(){
        return (
            
            <Layout>
                <SEO title="Gallery" />
                <div className="gallery-page">
              
                <div className="galleryImages">
                <img src={patio} alt="concrete patio"/>
                <img src={patio1} alt="concrete patio"/>
                
                <img src={patio4} alt="concrete patio"/>
                <img src={patio5} alt="concrete patio"/>
                <img src={stampedPatio} alt="stamped concrete patio"/>
                <img src={epoxy} alt="commercial, epoxy floor"/>
                <img src={fireplace} alt="concrete patio with fireplace"/>
                <img src={overlay} alt="concrete overlay"/>
                <img src={stamp} alt="stamped concrete patio"/>
                <img src={stamped} alt="stamped concrete patio"/>
                <img src={stone} alt="stamped concrete patio"/>
                <img src={wood} alt="stamped concrete patio"/>
                </div>
                </div>
            </Layout>
        
        )}
}

export default Gallery
